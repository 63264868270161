<template>
    <div class="navigation">

        <slot />

    </div>
</template>

<style lang="scss" scoped>

.navigation {

    padding-top: 1rem;

}

</style>